<template>
  <div class="block-banner-cta" :class="{ 'is-full-width': computedValues.fullwidth, 'is-full-image': computedValues.fullimage }">
    <div class="block-banner-cta__inner">
      <div class="block-banner-cta__content">
        <div v-if="computedValues.pretitle" class="block-banner-cta__pretitle">
          {{ computedValues.pretitle }}
        </div>
        <AtomHeading tag="h2" appereance="h1" :text="computedValues.title" />
        <p v-if="computedValues.lead" class="block-banner-cta__lead">
          {{ computedValues.lead }}
        </p>
        <AtomCta
          v-if="computedValues.link"
          :data="computedValues"
          :text="computedValues.linktext"
        />
      </div>
      <AtomImage
        v-if="computedValues.image"
        :data="computedValues.image"
        class="block-banner-cta__image"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
    Computed Data
*/
const storyStore = useStoryStore();

const computedValues = computed(() => {
    let title = props.data?.title || props.title;
    let pretitle = props.data?.pretitle || props.pretitle;
    let lead = props.data?.lead || props.lead;
    let link = props.data?.link || props.link;
    let linktext = props.data?.linktext || props.linktext;
    let image = props.data?.image || props.image;
    let fullwidth = props.data?.fullwidth || props.fullwidth;
    let fullimage = props.data?.fullimage || props.fullimage;

    if (props.data?.strapi_data_key) {
        const strapiData = props.data.strapi_data_key;

        title = storyStore.getConnectedStrapiValue(strapiData);
        pretitle = storyStore.getConnectedStrapiValue(strapiData);
        lead = storyStore.getConnectedStrapiValue(strapiData);
        link = storyStore.getConnectedStrapiValue(strapiData);
        linktext = storyStore.getConnectedStrapiValue(strapiData);
        image = storyStore.getConnectedStrapiValue(strapiData);
        fullwidth = storyStore.getConnectedStrapiValue(strapiData);
        fullimage = storyStore.getConnectedStrapiValue(strapiData);
    }

    return {
        title,
        pretitle,
        lead,
        link,
        linktext,
        image,
        fullwidth,
        fullimage,
    };
});

</script>

<style lang="scss">
.block-banner-cta__content {
    z-index: 2;
    max-width: 750px;

    @include mobile {
        margin-bottom: 10px;
    }

    .atom-heading {
        margin-bottom: 20px;
    }
}

.block-banner-cta {
    @include component-spacing(60px, 90px);
    @include fluid('padding-top', 20px, 94px);
    @include fluid('padding-bottom', 20px, 94px);
    @include fluid('padding-left', 20px, 87px);
    @include fluid('padding-right', 20px, 87px);
    @include grid-columns(4, 12);

    @include tablet {
        @include grid-columns(1, 12);
    }

    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 390px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: $C_GREEN_BRIGHT;
    color: $C_GREEN_DARK;

    &::before,
    &::after {
        position: absolute;
        right: 244px;
        bottom: 72px;
        width: 143px;
        height: 143px;
        background-color: $C_GREEN_DARK;
        content: '';
        filter: blur(114.5px);
        transform: matrix(-0.87, -0.49, 0.5, -0.87, 0, 0);
    }

    &::before {
        right: 0%;
        bottom: -50%;
        width: 30%;
        height: 150%;
        background-color: $C_PRIMARY;
        filter: blur(165px);
        opacity: 0.75;
    }

    &.is-full-width {
        @include grid-full();

        border-radius: 0;

        &::before,
        &::after {
            @include big-screens {
                right: auto;
                left: 1700px;
            }
        }
    }

    &:not(.is-full-width) {
        .atom-heading {
            @include fluid('font-size', 32px, 40px);
        }
    }

    .block-banner-cta__content {
        position: relative;
        z-index: 2;
        flex: 0 1 50%;

        @include tablet {
            flex-basis: 60%;
        }
    }
}

.block-banner-cta__inner {
    display: flex;
    height: 100%;
    flex: 0 1 1280px;
    align-items: center;
    justify-content: space-between;

    @include mobile {
        display: block;
    }
}

.block-banner-cta__image {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 350px;
    height: 100%;
    flex: 0 1 40%;
    margin-bottom: 0;

    @include mobile {
        height: 230px;
    }

    img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include mobile {
            object-fit: contain;
        }
    }

    &::before {
        @include fluid('height', 192px, 280px);
        @include fluid('width', 192px, 280px);

        position: absolute;
        z-index: 0;
        top: 60%;
        right: 50%;
        border-radius: 100%;
        background-color: $C_GREEN_BRIGHT;
        content: '';
        transform: translateY(-50%);
    }

    .is-full-width & {
        &::before {
            @include fluid('height', 192px, 460px);
            @include fluid('width', 192px, 460px);
        }
    }
}

.block-banner-cta__pretitle {
    @include typo-font('bold');
}

.block-banner-cta__lead {
    @include typo-font('light');
    @include typo-size('lead');

    margin-bottom: 10px;
}

.nav-mega-flyout__content {
    .block-banner-cta {
        min-height: 200px;
        padding: 20px 40px;
    }

    .block-banner-cta__image {
        img {
            object-fit: contain;
        }

        &::before {
            @include fluid('height', 100px, 200px);
            @include fluid('width', 100px, 200px);
        }

    }

    .block-banner-cta__content {
        flex: 0 1 60%;

        @include tablet {
            flex-basis: 60%;
        }
    }
}

.is-full-image {
    &::before,
    &::after {
        display: none;
    }

    &.block-banner-cta {
        @include mobile {
            min-height: 470px;
            align-items: flex-start;
        }
    }

    .block-banner-cta__image {
        position: absolute;
        top: 0;
        right: 0;
        width: 41%;
        max-width: unset;

        img {
            border-top-left-radius: 60px;
            object-fit: cover;
        }

        &::before {
            display: none;
        }

        @include mobile {
            top: unset;
            bottom: 0;
            width: 100%;
        }
    }
}
</style>
